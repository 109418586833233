import {
  ApplicationContextParameters,
  ApplicationContext,
  TEnvironment,
} from '@upside/spa-shared-context';

import { ApplicationConfiguration } from './configuration/application';
import { frontEnds } from './const';

export default (
  configuration: ApplicationConfiguration,
): Promise<ApplicationContext> => {
  const parameters: ApplicationContextParameters = {
    authenticationUrl: configuration.authenticationUrl as string,
    staticDataUrl: configuration.staticDataUrl as string,
    apiKey: configuration.apiKey as string,
    organisationUrl: configuration.organisationUrl as string,
    frontEnds,
    orgBlacklist: configuration.orgBlacklist as string[],
    environment: configuration.environment as TEnvironment,
  };

  return ApplicationContext.create(parameters);
};
