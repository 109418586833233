import { ApplicationContext } from '@upside/spa-shared-context';

import {
  init,
  makeFetchTransport,
  moduleMetadataIntegration,
  makeMultiplexedTransport,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/browser';

// A lot of this logic is based on the example in the Sentry documentation https://docs.sentry.io/platforms/javascript/best-practices/micro-frontends/

const EXTRA_KEY = 'ROUTE_TO';

const transport = makeMultiplexedTransport(makeFetchTransport, (args) => {
  const event = args.getEvent();
  if (
    event &&
    event.extra &&
    EXTRA_KEY in event.extra &&
    Array.isArray(event.extra[EXTRA_KEY])
  ) {
    return event.extra[EXTRA_KEY];
  }
  return [];
});

export default (context: ApplicationContext): void => {
  init({
    // DSN for the customer-portal project
    dsn: 'https://280975302ca14c5d9bfcdd1952a99fd1@o153324.ingest.us.sentry.io/4504960973340672',
    // Pass in the environment so we can filter it in Sentry
    environment: context.environment,
    integrations: [
      // Enable micro-frontends to have custom DSNs
      moduleMetadataIntegration(),
      // Enable tracing https://docs.sentry.io/platforms/javascript/configuration/integrations/browsertracing/
      browserTracingIntegration(),
      // Enable session replay https://docs.sentry.io/platforms/javascript/session-replay/
      replayIntegration(),
    ],
    tracesSampleRate: context.environment === 'production' ? 0.05 : 0,
    // Session Replay config
    replaysSessionSampleRate: 0, // Opt-out of all replays
    replaysOnErrorSampleRate: 1.0, // Except if there's an error - we want to capture a replay for each one. See https://docs.sentry.io/platforms/javascript/session-replay/#sampling
    transport,
    beforeSend: (event) => {
      if (event?.exception?.values?.[0]?.stacktrace?.frames) {
        const { frames } = event.exception.values[0].stacktrace;
        // Find the last frame with module metadata containing a DSN

        const validFrames = frames.filter(
          (frame) =>
            frame.module_metadata &&
            frame.module_metadata.dsn &&
            // Exclude errors from local (dev) versions of apps
            !frame.filename?.includes('http://localhost'),
        );

        // Ignore the event as it likely came from a local (dev) version of an app
        if (!validFrames.length) {
          return null;
        }

        // Use top frame to determine route
        const routeTo = validFrames.map((v) => v.module_metadata).slice(-1);

        if (routeTo.length) {
          return {
            ...event,
            extra: {
              ...event.extra,
              [EXTRA_KEY]: routeTo,
            },
          };
        }

        // The error came from an app that doesn't have an explicit micro-frontend Sentry project or from the portal.
        // For now, just capture it in the customer-portal project for now
        return event;
      }

      return null;
    },
  });
};
