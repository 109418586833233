import { ApplicationContext, StandardEvents } from '@upside/spa-shared-context';

import { SingleSpaCustomEventDetail } from 'single-spa';

import {
  handleLoginEvent,
  handleLogoutEvent,
  handleRoutingEvent,
} from './authentication-handlers';

type SingleSpaEvent = Event & { detail: SingleSpaCustomEventDetail };

export default (context: ApplicationContext): void => {
  context.eventBus.on(StandardEvents.Login, handleLoginEvent);
  context.eventBus.on(StandardEvents.Logout, handleLogoutEvent);

  window.addEventListener('single-spa:before-routing-event', (event) => {
    const {
      detail: { newUrl },
    } = event as SingleSpaEvent;

    handleRoutingEvent(newUrl, context.authentication.loggedIn);
  });
};
