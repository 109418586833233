import { ApplicationContext, StandardEvents } from '@upside/spa-shared-context';

import { infraflexAiAssistantAppName, basePaths } from 'shell/const';
import { getAppStatus, registerApplication, LifeCycles } from 'single-spa';

import application from '../application';

/**
 * Registers the AI assistant app when the AI modal is opened for the first time
 */
export default (context: ApplicationContext): void => {
  context.eventBus.on(StandardEvents.SetAiModalState, () => {
    // Only register the AI assistant app if it's not already registered
    if (!getAppStatus(infraflexAiAssistantAppName)) {
      registerApplication({
        activeWhen: () => context.authentication.loggedIn,
        app: () =>
          System.import(infraflexAiAssistantAppName) as Promise<LifeCycles>,
        name: infraflexAiAssistantAppName,
        customProps: {
          basePath: basePaths[application.name],
          context,
        },
      });
    }
  });
};
